import { defineRegion } from '@/helpers/defineRegion.js'
import { getCurrentCountryCode } from '@/api/api-country.js'
import i18n from '@/i18n/index.js'

export async function getCurrentLocale() {
	const countryCode = await getCurrentCountryCode()

	defineRegion(countryCode)

	const storedLang = getStoredLang()

	if (storedLang) {
		document.documentElement.lang = storedLang
		i18n.global.locale = storedLang
		return storedLang
	}

	let lang

	if (countryCode) {
		lang = countryCode.toLowerCase()
	} else {
		const language = navigator.language || navigator.userLanguage

		lang = language.split('-')[0]
	}

	if (locales.de.includes(lang)) {
		lang = 'de'
	} else if (locales.ru.includes(lang)) {
		lang = 'ru'
	} else if (locales.pl.includes(lang)) {
		lang = 'pl'
	} else {
		lang = 'en'
	}

	storeLang(lang)
	i18n.global.locale = lang
	document.documentElement.lang = lang

	return lang
}

export function getStoredLang() {
	return localStorage.getItem('lang')
}

export function storeLang(lang) {
	localStorage.setItem('lang', lang)
}

const locales = {
	de: ['de', 'lb', 'at', 'ch', 'lu', 'be', 'li'],
	ru: ['ru', 'kk', 'by', 'ky'],
	pl: ['pl']
}
