import landingDe from './landing/de'

export const deMessages = {
	de: {
		base: {
			cancel: 'Abbrechen',
			back: 'Zurück',
			close: 'Schließen',
			next: 'Nächste'
		},
		characters: {
			roxxid: 'Roxid',
			['cute girl']: 'Cutie',
			birdman: 'Birdman',
			skeleton: 'Skeleton'
		},
		home: {
			collections: 'Sammlungen',
			myHeroes: 'Meine Helden'
		},
		profile: {
			edit: 'Bearbeiten',
			chooseLang: 'Wählen Sie eine Sprache',
			logout: 'Abmelden',
			collectedHeroes: 'Gesammelte Helden'
		},
		editPhone: {
			enterPhone: 'Rufnummer eingeben',
			save: 'Speichern Sie',
			incorrectNumber: 'Falsche Nummer eingegeben',
			confirmation: 'Konfirmation',
			smsSent: 'SMS mit dem Code wurde an die angegebene Nummer gesendet',
			codeHint:
				'Um die Nummernänderung abzuschließen, geben Sie den 6-stelligen Aktivierungscode ein',
			secondsRemaining: 'Sekunden',
			hoursRemaining: 'Stunde',
			sendCodeAgain: 'Code erneut anfordern ',
			sendCodeAgainAfter: 'Nach {count} {type} erneut die Jahreszahl anfordern',
			send: 'Senden Sie',
			phoneChanged: 'Die Nummer wurde erfolgreich geändert',
			myProfile: 'Mein Profil',
			incorrectCode: 'Ungültiger Code',
			invalidCode: 'Falscher Code eingegeben'
		},
		greenSuccessModal: {
			title: 'Herzlichen Glückwunsch',
			buttonText: 'Ok'
		},
		menu: {
			titleCollections: 'Sammlungen',
			collections: 'Monzi - Sammlungen',
			myHeroes: 'Meine Helden',
			favorites: 'Favoriten',
			scan: 'Scannen',
			profile: 'Profil',
			contactUs: 'Kontakt',
			addApplicationIcon: 'Hinzufügen eines Anwendungssymbols'
		},
		contactUs: {
			title: 'Haben Sie eine Frage?',
			desc: 'Stellen Sie eine Frage zur Unterstützung',
			yourQuestion: 'Ihre Frage',
			send: 'Senden Sie',
			invalidEmail: 'E-Mail wird falsch eingegeben'
		},
		energyModal: {
			title: 'Verdienen Sie Ihre Energie!',
			desc: 'Klicke auf eine Figur, verdiene Energie und kaufe Animationen',
			button: 'Meine Helden'
		},
		search: {
			results: 'Suchergebnisse',
			empty: 'Leider wurde nichts gefunden',
			inputAria: 'Variation des Charakters',
			buttonAria: 'Variation finden'
		},
		characterSale: {
			modalTitle: 'Sind Sie bereit, einen Helden zu verkaufen?',
			buttonText: 'Verkaufen für'
		},
		characterBuy: {
			modalTitle: 'Held kaufen?',
			buttonText: 'Kaufen für',
			successModalDesc: 'Der Held wurde erfolgreich gekauft'
		},
		constructor: {
			buyCharacter: 'Kaufen Sie die Figur',
			myHero: 'Mein Held'
		},
		login: {
			registration: 'Registrierung',
			registrationVerb: 'Registrierung',
			registrationEnd: 'Registrierung abschließen',
			registrationEndDesc:
				'Bestätigen Sie Ihre E-Mail-Adresse, um die Registrierung abzuschließen.',
			registrationEndConfirm: 'Bestätigen Sie jetzt',
			registerDesc:
				'Erstellen Sie ein Konto und lernen Sie alle Charaktere kennen. Es dauert nur ein paar Minuten!',
			signIn: 'Anmelden',
			signInVerb: 'Anmelden',
			signInGoogle: 'Mit Google anmelden',
			signInFacebook: 'Mit Facebook anmelden',
			signInVK: 'Mit VK anmelden',
			signInEmail: 'Mit E-Mail anmelden',
			signUpButtonText: 'Registrieren Sie sich',
			iAgreeText: 'Ich akzeptiere',
			iAgreeLink: 'Bedingungen und Konditionen für die Verarbeitung personenbezogener Daten',
			inputEmail: 'E-Mail',
			inputPassword: 'Passwort',
			inputPasswordRepeat: 'Passwort wiederholen',
			showHidePassword: 'Kennwort anzeigen/ausblenden',
			requiredField: 'Dies ist ein Pflichtfeld',
			acceptTerms: 'Vereinbarung akzeptieren',
			incorrectEmail: 'Ungültige E-Mail',
			incorrectPassword: 'Ungültiges Passwort',
			incorrectPasswordType:
				'Passwort muss 6 bis 18 Zeichen enthalten, nur lateinische Buchstaben, davon 1 Großbuchstabe',
			incorrectPasswordMatch: 'Kennwörter stimmen nicht überein',
			incorrectCredentials: 'Falsche E-Mail oder falsches Passwort'
		},
		sign_up: {
			mail_used: 'Ein Benutzer mit dieser Email existiert bereits'
		},
		authGift: {
			openButton: 'Ein Geschenk öffnen',
			saveButton: 'Zur Sammlung speichern',
			saveToCollection: 'Zur Sammlung speichern',
			registerDesc:
				'Gib deine Telefonnummer ein, um den Code zu erhalten und die Figur in deiner Sammlung zu speichern',
			invalidQr: 'QR-Code ist nicht gültig',
			usedQr: 'Dieser QR-Code wurde bereits verwendet',
			qrNotFound: 'Dieser QR-Code wurde nicht gefunden',
			qrErrorButtonText: 'Startseite',
			qrSuccessText: 'Ein Zeichen wurde zu Ihrer Sammlung hinzugefügt',
			qrSuccessButtonText: 'Meine Sammlung'
		},
		favorites: {
			openInConstructor: 'Im Konstrukteur öffnen',
			buyHero: 'Held kaufen',
			actions: 'Aktionen',
			hide: 'Ausblenden',
			delete: 'Löschen',
			deleteConfirm:
				'Sind Sie sicher, dass Sie den Helden aus Ihren Favoriten entfernen möchten?',
			createVariant: 'Erstellen Sie eine Variante'
		},
		myHeroes: {
			tapOnMe: 'Klicken Sie auf mich',
			actions: 'Aktionen',
			value: 'Wert',
			exchange: 'Austausch',
			gift: 'Geschenk',
			sell: 'Verkaufen',
			hide: 'Ausblenden',
			purchase: 'Kaufen',
			buyAnimConfirmText: 'Sind Sie sicher, dass Sie diese Animation hinzufügen möchten?',
			addFor: 'Hinzufügen für',
			giftConfirmText: 'Sind Sie sicher, dass Sie ein Geschenk versenden möchten?',
			send: 'Senden Sie',
			tapHint: 'Tippen Sie auf den Bildschirm, um das Zeichen auf die Oberfläche zu setzen',
			okay: 'Ja'
		},
		gift: {
			hint: 'Zeigen Sie dem Geschenkempfänger den QR-Code',
			receiveConfirm: 'Möchten Sie das Geschenk annehmen?',
			receiveButtonText: 'Annehmen',
			successSent: 'Geschenk erfolgreich versendet',
			successSendButtonText: 'Meine Sammlung',
			successReceive: 'Das Geschenk wurde empfangen, Sie finden es in Ihrer Sammlung',
			successReceiveButtonText: 'Meine Sammlung'
		},
		exchange: {
			hint: 'Scannen, um Helden auszutauschen',
			suggestedExchange: 'Sie haben einen Tausch vorgeschlagen',
			awaitingReply: 'Erwarte Antwort',
			exchangeOfferText: 'Ihnen wird ein Tauschgeschäft angeboten',
			selectCharacter: 'Wählen Sie eine Figur aus Ihren Sammlungen zum Tausch gegen',
			hisHero: 'Sein Held',
			yourHero: 'Dein Held',
			choose: 'Wähle',
			refuse: 'Ablehnen',
			selectCollection: 'Wählen Sie eine Sammlung',
			next: 'Nächste',
			suggest: 'vorschlagen',
			confirmation: 'Konfirmation',
			confirmationDesk:
				'Bestätigen Sie die Bedingungen der Börse, wenn Sie mit den Bedingungen einverstanden sind',
			confirmButtonText: 'Bestätigen Sie',
			successCompleted: 'Austausch erfolgreich abgeschlossen',
			successButtonText: 'Meine Sammlung',
			characterAlreadyExist: 'Die Figur befindet sich bereits in Ihrer Sammlung',
			noOneCharacter: 'Sie haben noch keine Figuren'
		},
		errorModal: {
			title: 'Ups',
			desc: 'Etwas ist schief gelaufen. Versuchen Sie es erneut',
			buttonText: 'Ok'
		},
		pwaModal: {
			title: 'Wie füge ich ein Anwendungssymbol hinzu?',
			subTitle1: 'Klicken Sie auf die Schaltfläche Teilen',
			subTitle2: 'Zum Startbildschirm hinzufügen',
			subTitle3: 'Klicken Sie auf „Hinzufügen“',
			addButtonText: 'Hinzufügen',
			finishButtonText: 'Erledigt'
		},
		externalErrors: {
			qrCodeRequiredTitle: 'Kaufen Sie einen Artikel mit einem QR-Code',
			qrCodeRequired:
				'Die Registrierung ist nur für Benutzer möglich, die ein Produkt bei Monzi gekauft haben'
		},
		landing: { ...landingDe }
	}
}
