import landingPl from './landing/pl'

export const plMessages = {
	pl: {
		base: {
			cancel: 'Anuluj',
			back: 'Powrót',
			close: 'Zamknąć',
			next: 'Następny'
		},
		characters: {
			roxxid: 'Roxid',
			['cute girl']: 'Cutie',
			birdman: 'Birdman',
			skeleton: 'Skeleton'
		},
		home: {
			collections: 'Kolekcje',
			myHeroes: 'Moi bohaterowie'
		},
		profile: {
			edit: 'Edytuj',
			chooseLang: 'Wybierz język',
			logout: 'Wylogowanie',
			collectedHeroes: 'Zebrani bohaterowie'
		},
		editPhone: {
			enterPhone: 'Wprowadź numer telefonu',
			save: 'Zapisz',
			incorrectNumber: 'Wprowadzono nieprawidłowy numer',
			confirmation: 'Potwierdzenie',
			smsSent: 'Wiadomość SMS z kodem została wysłana na podany numer',
			codeHint: 'Aby zakończyć zmianę numeru, wprowadź 6-cyfrowy kod aktywacyjny',
			secondsRemaining: 'sekundy',
			hoursRemaining: 'godzina',
			sendCodeAgain: 'Ponownie poproś o kod',
			sendCodeAgainAfter: 'Ponowne żądanie kodu po {count} {type}',
			send: 'Wyślij',
			phoneChanged: 'Numer został pomyślnie zmieniony',
			myProfile: 'Mój profil',
			incorrectCode: 'Nieprawidłowy kod',
			invalidCode: 'Wprowadzono nieprawidłowy kod'
		},
		greenSuccessModal: {
			title: 'Gratulacje',
			buttonText: 'Ok'
		},
		menu: {
			titleCollections: 'Kolekcje',
			collections: 'Monzi - kolekcje',
			myHeroes: 'Moi bohaterowie',
			favorites: 'Ulubione',
			scan: 'Skanowanie',
			profile: 'Profil',
			contactUs: 'Skontaktuj się z nami',
			addApplicationIcon: 'Dodaj ikonę aplikacji'
		},
		contactUs: {
			title: 'Masz pytanie?',
			desc: 'Zadaj pytanie do pomocy technicznej',
			yourQuestion: 'Twoje pytanie',
			send: 'Wyślij',
			invalidEmail: 'Adres e-mail został wprowadzony nieprawidłowo'
		},
		energyModal: {
			title: 'Zarabiaj na swojej energii!',
			desc: 'Klikaj na postaci, zdobywaj energię i kupuj animacje',
			button: 'Moi bohaterowie'
		},
		search: {
			results: 'Wyniki wyszukiwania',
			empty: 'Niestety, nic nie znaleziono',
			inputAria: 'Odmiana postaci',
			buttonAria: 'Znajdź odmianę'
		},
		characterSale: {
			modalTitle: 'Czy jesteś gotowy sprzedać bohatera?',
			buttonText: 'Sprzedam za'
		},
		characterBuy: {
			modalTitle: 'Kup bohatera?',
			buttonText: 'Kup za',
			successModalDesc: 'Bohater został pomyślnie zakupiony'
		},
		constructor: {
			buyCharacter: 'Kup postać',
			myHero: 'Mój bohater'
		},
		login: {
			registration: 'Rejestracja',
			registrationVerb: 'Zarejestruj się',
			registrationEnd: 'Zakończ rejestrację',
			registrationEndDesc: 'Potwierdź swój adres e-mail, aby zakończyć rejestrację',
			registrationEndConfirm: 'Potwierdź teraz',
			registerDesc:
				'Utwórz konto, aby poznać wszystkie postacie. To zajmie tylko kilka minut!',
			signIn: 'Zaloguj się',
			signInVerb: 'Zaloguj się',
			signInGoogle: 'Zaloguj się za pomocą Google',
			signInFacebook: 'Zaloguj się za pomocą Facebooka',
			signInVK: 'Zaloguj się za pomocą vk',
			signInEmail: 'Zaloguj się za pomocą adresu e-mail',
			signUpButtonText: 'Zaloguj się',
			iAgreeText: 'Akceptuję',
			iAgreeLink: 'warunki przetwarzania danych osobowych',
			inputEmail: 'Email',
			inputPassword: 'Hasło',
			inputPasswordRepeat: 'Powtórz hasło',
			showHidePassword: 'Pokaż/Ukryj Hasło',
			requiredField: 'To jest wymagane pole',
			acceptTerms: 'Akceptacja umowy',
			incorrectEmail: 'Nieprawidłowy adres e-mail',
			incorrectPassword: 'Nieprawidłowe hasło',
			incorrectPasswordType:
				'Hasło musi zawierać od 6 do 18 znaków, wyłącznie litery alfabetu łacińskiego, w tym 1 wielką literę',
			incorrectPasswordMatch: 'Hasła nie są zgodne',
			incorrectCredentials: 'Nieprawidłowy adres e-mail lub hasło'
		},
		sign_up: {
			mail_used: 'Użytkownik z tym adresem e-mail już istnieje'
		},
		authGift: {
			openButton: 'Otwórz prezent',
			saveButton: 'Zapisz do kolekcji',
			saveToCollection: 'Zapisz do kolekcji',
			registerDesc:
				'Wprowadź swój numer telefonu, aby otrzymać kod i zapisać postać w swojej kolekcji',
			invalidQr: 'Kod QR jest nieprawidłowy',
			usedQr: 'Ten kod QR został już użyty',
			qrNotFound: 'Ten kod QR nie został znaleziony',
			qrErrorButtonText: 'Strona główna',
			qrSuccessText: 'Postać została dodana do twojej kolekcji',
			qrSuccessButtonText: 'Moja kolekcja'
		},
		favorites: {
			openInConstructor: 'Otwórz w konstruktorze',
			buyHero: 'Kup bohatera',
			actions: 'Akcje',
			hide: 'Ukryj',
			delete: 'Usuń',
			deleteConfirm: 'Czy na pewno chcesz usunąć bohatera z ulubionych?',
			createVariant: 'Utwórz wariant'
		},
		myHeroes: {
			tapOnMe: 'Kliknij na mnie',
			actions: 'Działania',
			value: 'Wartość',
			exchange: 'Wymiana',
			gift: 'Prezent',
			sell: 'Sprzedaż',
			hide: 'Ukryj',
			purchase: 'Zakup',
			buyAnimConfirmText: 'Czy na pewno chcesz dodać tę animację?',
			addFor: 'Dodaj dla',
			giftConfirmText: 'Czy na pewno chcesz wysłać prezent?',
			send: 'Wyślij',
			tapHint: 'Dotknij ekranu, aby ustawić znak na powierzchni',
			okay: 'Dobrze'
		},
		gift: {
			hint: 'Pokaż odbiorcy prezentu kod QR',
			receiveConfirm: 'Czy chcesz zaakceptować prezent?',
			receiveButtonText: 'Zaakceptuj',
			successSent: 'Prezent został pomyślnie wysłany',
			successSendButtonText: 'Moja kolekcja',
			successReceive: 'Prezent został odebrany, znajdziesz go w swojej kolekcji',
			successReceiveButtonText: 'Moja kolekcja'
		},
		exchange: {
			hint: 'Skanowanie do wymiany bohaterów',
			suggestedExchange: 'Zaproponowałeś wymianę',
			awaitingReply: 'Czekam na odpowiedź',
			exchangeOfferText: 'Otrzymujesz ofertę wymiany',
			selectCharacter: 'Wybierz postać z kolekcji, którą chcesz wymienić',
			hisHero: 'Jego bohater',
			yourHero: 'Twój bohater',
			choose: 'Wybierz',
			refuse: 'Odrzuć',
			selectCollection: 'Wybierz kolekcję',
			next: 'Następny',
			suggest: 'Zaproponuj',
			confirmation: 'Potwierdzenie',
			confirmationDesk: 'Potwierdź warunki wymiany, jeśli zgadzasz się na jej warunki',
			confirmButtonText: 'Potwierdzenie',
			successCompleted: 'Wymiana zakończona pomyślnie',
			successButtonText: 'Moja kolekcja',
			characterAlreadyExist: 'Postać jest już w Twojej kolekcji',
			noOneCharacter: 'Nie masz żadnych postaci'
		},
		errorModal: {
			title: 'Ups',
			desc: 'Coś poszło nie tak. Spróbuj ponownie',
			buttonText: 'Ok'
		},
		pwaModal: {
			title: 'Jak dodać ikonę aplikacji?',
			subTitle1: 'Kliknij przycisk udostępniania',
			subTitle2: 'Dodaj do ekranu głównego',
			subTitle3: 'Kliknij „Dodaj”',
			addButtonText: 'Dodaj',
			finishButtonText: 'Gotowe'
		},
		externalErrors: {
			qrCodeRequiredTitle: 'Zakup produktu z kodem QR',
			qrCodeRequired:
				'Rejestracja jest możliwa tylko dla użytkowników, którzy zakupili produkt w Monzi'
		},
		landing: { ...landingPl }
	}
}
