import landingRu from './landing/ru'

export const ruMessages = {
	ru: {
		base: {
			cancel: 'Отмена',
			back: 'Назад',
			close: 'Закрыть',
			next: 'Далее'
		},
		characters: {
			roxxid: 'Роксид',
			['cute girl']: 'Мила',
			birdman: 'Клюв',
			skeleton: 'Скелетон'
		},
		home: {
			collections: 'Коллекции',
			myHeroes: 'Мои герои'
		},
		profile: {
			edit: 'Редактировать',
			chooseLang: 'Выберите язык',
			logout: 'Выход',
			collectedHeroes: 'Собрано героев'
		},
		editPhone: {
			enterPhone: 'Введите новый номер',
			save: 'Сохранить',
			incorrectNumber: 'Неверно введен номер',
			confirmation: 'Подтверждение',
			smsSent: 'На указанный номер было отправлено SMS c кодом',
			codeHint: 'Чтобы завершить смену номера, введите 6-значный код активации',
			secondsRemaining: 'сек',
			hoursRemaining: 'ч',
			sendCodeAgain: 'Запросить код повторно',
			sendCodeAgainAfter: 'Запросить код повторно через {count} {type}',
			send: 'Отправить',
			phoneChanged: 'Номер успешно изменен',
			myProfile: 'Мой профиль',
			incorrectCode: 'Неверный код',
			invalidCode: 'Неверно введён код'
		},
		greenSuccessModal: {
			title: 'Поздравляем',
			buttonText: 'Ок'
		},
		menu: {
			titleCollections: 'Коллекции',
			collections: 'Monzi - коллекции',
			myHeroes: 'Мои герои',
			favorites: 'Избранное',
			scan: 'Сканировать',
			profile: 'Профиль',
			contactUs: 'Связаться с нами',
			addApplicationIcon: 'Добавить Иконку приложения'
		},
		contactUs: {
			title: 'Остались вопросы?',
			desc: 'Задайте их в наш чат поддержки',
			yourQuestion: 'Ваш вопрос',
			send: 'Отправить',
			invalidEmail: 'Email введен неверно'
		},
		energyModal: {
			title: 'Зарабатывай энергию!',
			desc: 'Нажимай на персонажа, зарабатывай энергию, и покупай анимацию',
			button: 'Мои герои'
		},
		search: {
			results: 'Результаты поиска',
			empty: 'К сожалению, ничего не найдено',
			inputAria: 'Вариация персонажа',
			buttonAria: 'Найти вариацию'
		},
		characterSale: {
			modalTitle: 'Вы готовы продать героя?',
			buttonText: 'Продать за'
		},
		characterBuy: {
			modalTitle: 'Купить героя?',
			buttonText: 'Купить за',
			successModalDesc: 'Герой успешно куплен'
		},
		constructor: {
			buyCharacter: 'Купить персонажа',
			myHero: 'Мой герой'
		},
		login: {
			registration: 'Регистрация',
			registrationVerb: 'Зарегистрироваться',
			registrationEnd: 'Завершите регистрацию',
			registrationEndDesc:
				'Подтвердите свой адрес электронной почты, чтобы завершить регистрацию.',
			registrationEndConfirm: 'Подтвердить сейчас',
			registerDesc:
				'Создайте аккаунт, чтобы познакомиться со всеми персонажами. Это займет всего пару минут!',
			signIn: 'Вход',
			signInVerb: 'Войти',
			signInGoogle: 'Войти с Google',
			signInFacebook: 'Войти с Facebook',
			signInVK: 'Войти с vk',
			signInEmail: 'Войти через Email',
			signUpButtonText: 'Зарегистрироваться',
			iAgreeText: 'Я принимаю',
			iAgreeLink: 'условия на обработку персональных данных',
			inputEmail: 'Email',
			inputPassword: 'Пароль',
			inputPasswordRepeat: 'Повторите пароль',
			showHidePassword: 'Показать/скрыть пароль',
			requiredField: 'Это обязательное поле',
			acceptTerms: 'Примите соглашение',
			incorrectEmail: 'Неверный Email',
			incorrectPassword: 'Неверный пароль',
			incorrectPasswordType:
				'Пароль должен содержать от 6 до 18 символов, только латинские буквы, в том числе 1 заглавную',
			incorrectPasswordMatch: 'Пароли не совпадают',
			incorrectCredentials: 'Неправильный email или пароль'
		},
		sign_up: {
			mail_used: 'Пользователь с таким Email уже существует'
		},
		authGift: {
			openButton: 'Открыть Подарок',
			saveButton: 'Сохранить в коллекцию',
			saveToCollection: 'Сохранить в коллекцию',
			registerDesc:
				'Введите номер телефона, чтобы получить код и сохранить персонажа в коллекцию',
			invalidQr: 'QR-код не действителен',
			usedQr: 'Данный QR-код уже был использован',
			qrNotFound: 'Данный QR-код не найден',
			qrErrorButtonText: 'На главную',
			qrSuccessText: 'Персонаж добавлен в вашу коллекцию',
			qrSuccessButtonText: 'Моя коллекция'
		},
		favorites: {
			openInConstructor: 'Открыть в конструкторе',
			buyHero: 'Купить героя',
			actions: 'Действия',
			hide: 'Скрыть',
			delete: 'Удалить',
			deleteConfirm: 'Вы уверенны что хотите удалить героя из Избранных?',
			createVariant: 'Создать вариант'
		},
		myHeroes: {
			tapOnMe: 'Нажми на меня',
			actions: 'Действия',
			value: 'Ценность',
			exchange: 'Обменять',
			gift: 'Подарить',
			sell: 'Продать',
			hide: 'Скрыть',
			purchase: 'Покупка',
			buyAnimConfirmText: 'Вы уверенны что хотите добавить данную анимацию?',
			addFor: 'Добавить за',
			giftConfirmText: 'Вы уверенны, что хотите отправить подарок?',
			send: 'Отправить',
			tapHint: 'Тапните по экрану, чтобы установить персонажа на поверхности',
			okay: 'Хорошо'
		},
		gift: {
			hint: 'Покажите QR-код получателю подарка',
			receiveConfirm: 'Вы хотите принять подарок?',
			receiveButtonText: 'Принять',
			successSent: 'Подарок успешно отправлен',
			successSendButtonText: 'Моя коллекция',
			successReceive: 'Подарок получен, вы можете найти его в своей коллекции',
			successReceiveButtonText: 'Моя коллекция'
		},
		exchange: {
			hint: 'Отсканируйте, чтобы обменяться героями',
			suggestedExchange: 'Вы предложили обмен',
			awaitingReply: 'Ожидаем ответ',
			exchangeOfferText: 'Вам предлагают обмен',
			selectCharacter:
				'Выберите персонажа из своих коллекций, на которого вы хотите обменяться',
			hisHero: 'Его герой',
			yourHero: 'Ваш герой',
			choose: 'Выбрать',
			refuse: 'Отказаться',
			selectCollection: 'Выберите коллекцию',
			next: 'Далее',
			suggest: 'Предложить',
			confirmation: 'Подтверждение',
			confirmationDesk: 'Подтвердите условия обмена, если согласны с его условиями',
			confirmButtonText: 'Подтвердить',
			successCompleted: 'Обмен успешно завершен',
			successButtonText: 'Моя коллекция',
			characterAlreadyExist: 'Персонаж уже присутствует в коллекции',
			noOneCharacter: 'У вас нет ни одного персонажа'
		},
		errorModal: {
			title: 'Упс',
			desc: 'Что-то пошло не так. Попробуйте еще раз',
			buttonText: 'Ок'
		},
		pwaModal: {
			title: 'Как добавить инонку приложения?',
			subTitle1: 'Нажмите кнопку поделиться',
			subTitle2: 'Нажмите «На экран "Домой"»',
			subTitle3: 'Нажмите "Добавить"',
			addButtonText: 'Добавить',
			finishButtonText: 'Готово'
		},
		externalErrors: {
			qrCodeRequiredTitle: 'Приобретите товар с QR-кодом',
			qrCodeRequired:
				'Регистрация возможна только пользователям которые приобрели товар с Monzi'
		},
		landing: { ...landingRu }
	}
}
