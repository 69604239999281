export const defineRegion = (countryCode) => {
	if (!countryCode) {
		countryCode = 'other region'
	} else {
		countryCode = countryCode.toLowerCase()
	}

	let region

	if (regions.sng.includes(countryCode)) {
		region = 'sng'
	} else if (regions.pl.includes(countryCode)) {
		region = 'pl'
	} else {
		region = 'other'
	}

	storeRegion(region)
}

export function getStoredRegion() {
	return localStorage.getItem('region')
}

function storeRegion(region) {
	localStorage.setItem('region', region)
}

const regions = {
	sng: ['ru', 'kz', 'by', 'kg', 'ge'],
	pl: ['pl']
}
