export default {
	general: {},
	characters: {
		roxxid: {
			name: 'Roxid',
			excerpt:
				'Roxid ist ein Cyber-Abenteurer, der für seine waghalsigen Abenteuer in virtuellen Welten bekannt ist. Sein farbenfrohes Kostüm und seine Hightech-Brille ermöglichen es ihm, sich an jede Umgebung anzupassen. Er ist immer auf der Suche nach neuen Herausforderungen und Abenteuern.',
			desc: '<p><strong>Roxid</strong> ist ein Cyber-Abenteurer, der für seine waghalsigen Abenteuer in virtuellen Welten bekannt ist. Sein farbenfrohes Kostüm und seine Hightech-Brille ermöglichen es ihm, sich an jede Umgebung anzupassen. Er ist immer auf der Suche nach neuen Herausforderungen und Abenteuern.</p><p><strong>Ethnie:</strong> Mensch<br><strong>Charakter:</strong> Zurückhaltend, intelligent, selbstlos</p>'
		},
		mila: {
			name: 'Cutie',
			excerpt:
				'Cutie ist ein verträumtes, romantisches Mädchen, das immer süße Sachen trägt. Sie liebt süße Farben und weiche Stoffe, die ihr freundliches und verspieltes Wesen widerspiegeln. Sie ist immer bereit, ihre Freunde zu unterstützen und einen Hauch von Magie und Freude in jeden Tag zu bringen.',
			desc: '<p><strong>Cutie</strong> ist ein verträumtes, romantisches Mädchen, das immer süße Sachen trägt. Sie liebt süße Farben und weiche Stoffe, die ihr freundliches und verspieltes Wesen widerspiegeln. Sie ist immer bereit, ihre Freunde zu unterstützen und einen Hauch von Magie und Freude in jeden Tag zu bringen.</p><p><strong>Ethnie:</strong> Mensch<br><strong>Charakter:</strong> Süß, einfühlsam, sparsam</p>'
		},
		beak: {
			name: 'Birdman',
			excerpt:
				'Birdman ist ein rätselhafter Krieger mit einem scharfen Auge für die Lösung jedes Geheimnisses. Seine Kostüme können sowohl Angst als auch ein Lächeln hervorrufen. Er ist schweigsam und ernst, aber wenn er spricht, sind seine Worte immer voller Weisheit und Kraft.',
			desc: '<p><strong>Birdman</strong> ist ein rätselhafter Krieger mit einem scharfen Auge für die Lösung jedes Geheimnisses. Seine Kostüme können sowohl Angst als auch ein Lächeln hervorrufen. Er ist schweigsam und ernst, aber wenn er spricht, sind seine Worte immer voller Weisheit und Kraft.</p><p><strong>Ethnie:</strong> Птицы<br><strong>Charakter:</strong> Загадочный, Справедливый, Верный</p>'
		},
		skeleton: {
			name: 'Skeleton',
			excerpt:
				'Skeleton ist ein lebendiger Geist des Abenteuers und des Spaßes, immer bereit für Action. Sein stilvolles Outfit passt zu seiner lässigen Lebenseinstellung und seinem kühnen Geist. Trotz seines furchteinflößenden Aussehens hat er eine unbeschwerte Persönlichkeit und den Wunsch zu zeigen, dass Lachen und Mut Hand in Hand gehen.',
			desc: '<p><strong>Skeleton</strong> ist ein lebendiger Geist des Abenteuers und des Spaßes, immer bereit für Action. Sein stilvolles Outfit passt zu seiner lässigen Lebenseinstellung und seinem kühnen Geist. Trotz seines furchteinflößenden Aussehens hat er eine unbeschwerte Persönlichkeit und den Wunsch zu zeigen, dass Lachen und Mut Hand in Hand gehen.</p><p><strong>Ethnie:</strong> Kreaturen<br><strong>Charakter:</strong> Fröhlich, Freundlich, Mutig</p>'
		}
	},
	products: {
		product_1: {
			name: 'Glückskekse in einer Markenschachtel'
		},
		product_2: {
			name: 'Glückskekse in einer Markenschachtel für 6 Stück'
		},
		product_3: {
			name: 'Glückskekse in einer Markenschachtel'
		}
	},
	markets: {
		amazon: {
			name: 'Amazon'
		},
		allegro: {
			name: 'Allegro'
		},
		funcookies: {
			name: 'Funcookies'
		},
		wildberries: {
			name: 'Wildberries'
		}
	},
	news: {
		news_1: {
			title: 'MONZI. Triff deine Helden',
			desc: '<p>4 absolut einzigartige Charaktere, jeder mit seiner eigenen Geschichte und seinen eigenen Eigenschaften, bereit, sich auf ein unvergessliches Abenteuer in deiner Welt einzulassen. Finde heraus, wer sie sind und wie sie deine Realität verändern werden!</p>'
		},
		news_2: {
			title: 'Mehr MONZI - mehr Möglichkeiten',
			desc: '<p>Jeder neue Held ist ein Schritt hin zu einer kompletten Sammlung und neuen Fähigkeiten. Sei einer der Ersten, der alle MONZIs sammelt, tausche mit Freunden und steigere die Fähigkeiten deiner Helden bis zum Maximum!</p>'
		},
		news_3: {
			title: 'Erzähle deine MONZI-Geschichte: Wer wird dein Favorit sein?',
			desc: '<p>Roxid, Cutie, Birdman und Skeleton sind vier exklusive Helden, die für spannende Abenteuer bereit sind! Wähle deinen Favoriten und teile deinen Fortschritt mit deinen Freunden in den sozialen Medien!</p>'
		},
		news_4: {
			title: 'MONZI: Deine Helden, deine Regeln',
			desc: '<p>Alle Charaktere verfügen über mehrere Erscheinungsbilder und spektakuläre Animationen, die auf dich warten. Welches Aussehen wirst du kreieren? Verwandle deinen MONZI in einen einzigartigen Charakter, der nur dich widerspiegelt!</p>'
		}
	},
	product_links: {
		buy_on_amazon: 'auf Amazon einkaufen',
		buy_on_wildberries: 'kaufen bei Wildberries',
		buy_on_allegro: 'bei Allegro kaufen',
		buy_on_funcookies: 'bei FUNCOOKIES kaufen'
	},
	components: {
		app_footer: {},
		app_header: {
			scene: 'Schauplatz',
			show_qr: 'QR-Code anzeigen'
		},
		app_modal: {
			close: 'Schließen'
		}
	},
	home_view: {
		home_hero: {
			title: 'MONZI AR Heroes Kollektion',
			desc: 'Verpassen Sie nicht die Gelegenheit, ein spannendes Spiel zu spielen, bei dem Sie sich in einer Welt voller Abenteuer verlieren',
			link: 'GO TO PRODUCTS',
			cards: {
				card_1: {
					title: 'ZEIGE ES DEINEN FREUNDEN',
					desc: 'Nimm AR-Videos oder Fotos auf und teile sie in den sozialen Netzwerken'
				},
				card_2: {
					title: 'PUMPEN SIE IHRE HELDEN AUF',
					desc: 'Baue Energie auf und nutze sie für Animationen'
				},
				card_3: {
					title: 'MONZIES VERKAUFEN',
					desc: 'Verpassen Sie nicht die Gelegenheit, ein spannendes Spiel zu spielen, bei dem Sie sich in einer Welt voller Abenteuer verlieren'
				},
				card_4: {
					title: 'CHARAKTERE TAUSCHEN',
					desc: 'Verpassen Sie nicht die Gelegenheit, ein spannendes Spiel zu spielen, bei dem Sie sich in einer Welt voller Abenteuer verlieren'
				}
			}
		},
		home_about: {
			title: 'ÜBER DIE HELDEN DER SAMMLUNG',
			subtitle: 'ÜBER PERSÖNLICHKEITEN'
		},
		home_how_it_works: {
			title: 'WIE ES FUNKTIONIERT',
			cards: {
				card_1: {
					title: 'Kekse kaufen',
					desc: 'Kaufe ein Produkt mit einer MONZI-Figur darin'
				},
				card_2: {
					title: 'MONZI ERHALTEN',
					desc: 'Scanne den QR-Code auf dem Produkt und hole dir deinen MONZI!'
				},
				card_3: {
					title: 'SAMMELN',
					desc: 'Baue deine einzigartige Sammlung auf und tausche Monzies mit Freunden'
				}
			}
		},
		home_products: {
			title: 'Produkte mit MONZI',
			buy_btn: 'KAUFEN'
		},
		home_markets: {
			title: 'Wo Sie kaufen können'
		},
		home_news: {
			title: 'Nachrichten',
			show_more: 'Mehr anzeigen'
		},
		home_faq: {
			title: 'FAQ',
			show_answer: 'Antwort anzeigen',
			card_1: {
				title: 'Wie bekommt man Animation für eine Figur?',
				desc: 'Du kannst Animationen für deinen Charakter gegen Energie kaufen. Energie wird gesammelt, wenn du auf deinen Charakter im Bereich „Meine Charaktere“ klickst.'
			},
			card_2: {
				title: 'Wie erhalte ich einen bestimmten Charakter aus einem Cookie?',
				desc: 'Du kannst keinen bestimmten Charakter bekommen, alle Charaktere werden zufällig generiert und niemand weiß, welchen Charakter du nach dem Scannen des QR-Codes erhalten wirst. Aber du kannst eine Figur für die virtuelle Währung „Cookies“ kaufen. Du kannst sie verdienen, indem du Figuren aus deiner Sammlung verkaufst'
			},
			card_3: {
				title: 'Was kann ich mit Monzi-Figuren machen?',
				desc: 'Du kannst Monzi-Figuren tauschen, verschenken, verkaufen, kaufen und handeln. Du kannst auch Augmented-Reality-Fotos von deinen Charakteren machen und sie in sozialen Netzwerken teilen. Du hast die Möglichkeit, deinen Monzi zu individualisieren, indem du verschiedene Kleidungsstücke und Accessoires an ihm anprobierst'
			},
			card_4: {
				title: 'Warum einige Monzi-Figuren teurer sind?',
				desc: 'Einige Figuren sind tatsächlich teurer, weil sie seltener sind und die Wahrscheinlichkeit, dass sie herausfallen, geringer ist'
			}
		}
	},
	privacy_policy_view: {
		title: 'Datenschutzerk&shy;lärung',
		content: `<p>Dieses Dokument „Datenschutzerklärung“ (im Folgenden „Richtlinie“ genannt) stellt die Regeln für die Verwendung von Daten der Internetnutzer durch /Seiteninhaber angeben/ (im Folgenden „wir“ und/oder „Administration“) dar, die über die Website /URL der Website angeben/ (im Folgenden „Website“) gesammelt werden.</p>
				<h6>1. Verarbeitete Daten</h6>
				<p>1.1. Wir sammeln keine personenbezogenen Daten von Ihnen über die Website.</p>
				<p>1.2. Alle auf der Website gesammelten Daten werden in anonymisierter Form bereitgestellt und empfangen (im Folgenden „Anonymisierte Daten“).</p>
				<p>1.3. Anonymisierte Daten umfassen folgende Informationen, die Sie nicht identifizieren:</p>
				<p>1.3.1. Informationen, die Sie über Online-Formulare und Softwaremodule der Website freiwillig über sich bereitstellen, einschließlich Name, Telefonnummer und/oder E-Mail-Adresse.</p>
				<p>1.3.2. Daten, die je nach den Einstellungen der von Ihnen verwendeten Software in anonymisierter Form automatisch übermittelt werden.</p>
				<p>1.4. Die Administration ist berechtigt, Anforderungen an die Zusammensetzung der Anonymisierten Daten des Nutzers festzulegen, die über die Website gesammelt werden.</p>
				<p>1.5. Wenn bestimmte Informationen nicht als obligatorisch gekennzeichnet sind, erfolgt deren Bereitstellung oder Offenlegung nach Ihrem Ermessen. Gleichzeitig geben Sie Ihr informiertes Einverständnis, dass ein unbegrenzter Personenkreis Zugriff auf diese Daten haben darf. Diese Daten werden ab dem Moment ihrer Bereitstellung und/oder anderweitigen Offenlegung öffentlich zugänglich.</p>
				<p>1.6. Die Administration überprüft nicht die Richtigkeit der bereitgestellten Daten und ob der Nutzer die erforderliche Zustimmung zu ihrer Verarbeitung gemäß dieser Richtlinie besitzt, und geht davon aus, dass der Nutzer in gutem Glauben, sorgfältig handelt und alle erforderlichen Anstrengungen unternimmt, um diese Informationen auf dem neuesten Stand zu halten und alle erforderlichen Einwilligungen für deren Verwendung einzuholen.</p>
				<p>1.7. Sie erkennen die Möglichkeit an und stimmen zu, dass auf der Website Software von Dritten verwendet werden kann, wodurch solche Dritten die in Ziff. 1.3 genannten Daten in anonymisierter Form erhalten und übermitteln können.</p>
				<p><strong>Beispiel!</strong> Zu dieser Software von Dritten gehören Systeme zur Erfassung von Besucherstatistiken wie Google Analytics und Yandex.Metrica.</p>
				<p>1.8. Die Zusammensetzung und Bedingungen für die Erfassung anonymisierter Daten durch Drittdienste werden direkt von deren Rechteinhabern festgelegt und können Folgendes umfassen:</p>
				<ul>
				<li>Browserdaten (Typ, Version, Cookies);</li>
				<li>Gerätedaten und dessen Standort;</li>
				<li>Betriebssystemdaten (Typ, Version, Bildschirmauflösung);</li>
				<li>Anfragedaten (Zeit, Quelle der Weiterleitung, IP-Adresse).</li>
				</ul>
				<p>1.9. Die Administration ist nicht verantwortlich für die Verwendung der anonymisierten Nutzerdaten durch Dritte.</p>
				<h6>2. Zwecke der Datenverarbeitung</h6>
				<p>2.1. Die Administration verwendet die Daten zu folgenden Zwecken:</p>
				<p>2.1.1. Bearbeitung eingehender Anfragen und Kontaktaufnahme mit dem Nutzer;</p>
				<p>2.1.2. Informationsdienste, einschließlich des Versands von Werbe- und Informationsmaterialien;</p>
				<p>2.1.3. Durchführung von Marketing-, Statistik- und anderen Studien;</p>
				<p>2.1.4. Zielgruppenorientierung von Werbematerialien auf der Website.</p>
				<h6>3. Anforderungen an den Datenschutz</h6>
				<p>3.1. Die Administration speichert die Daten und schützt sie vor unbefugtem Zugriff und Verbreitung gemäß internen Regeln und Vorschriften.</p>
				<p>3.2. Die erhaltenen Daten werden vertraulich behandelt, außer in Fällen, in denen sie vom Nutzer öffentlich zugänglich gemacht wurden, sowie in Fällen, in denen die auf der Website verwendeten Technologien und Software von Dritten oder die Einstellungen der vom Nutzer verwendeten Software einen offenen Datenaustausch mit diesen Personen und/oder anderen Teilnehmern und Nutzern des Internets vorsehen.</p>
				<p>3.3. Zur Verbesserung der Arbeitsqualität ist die Administration berechtigt, Log-Dateien über die vom Nutzer auf der Website durchgeführten Aktionen für einen Zeitraum von 1 (einem) Jahr zu speichern.</p>
				<h6>4. Przekazywanie danych</h6>
				<p>4.1. Die Administration ist berechtigt, Daten in folgenden Fällen an Dritte weiterzugeben:</p>
				<ul>
				<li>Der Nutzer hat seine Zustimmung zu solchen Handlungen gegeben, einschließlich der Fälle, in denen der Nutzer Einstellungen der von ihm verwendeten Software verwendet, die die Bereitstellung bestimmter Informationen nicht einschränken;</li>
				<li>Die Weitergabe ist im Rahmen der Nutzung der Funktionalitäten der Website durch den Nutzer erforderlich;</li>
				<li>Die Weitergabe ist gemäß den Zwecken der Datenverarbeitung erforderlich;</li>
				<li>Im Zusammenhang mit der Übertragung der Website in den Besitz, Gebrauch oder das Eigentum eines Dritten;</li>
				<li>Auf Anforderung eines Gerichts oder einer anderen zuständigen staatlichen Stelle im Rahmen des gesetzlich vorgeschriebenen Verfahrens;</li>
				<li>Zum Schutz der Rechte und rechtlichen Interessen der Administration im Zusammenhang mit Verstößen des Nutzers.</li>
				</ul>
				<h6>Änderung der Datenschutzerklärung</h6>
				<p>5.1. Diese Richtlinie kann von der Administration einseitig ohne vorherige Benachrichtigung des Nutzers geändert oder beendet werden. Die neue Fassung der Richtlinie tritt ab dem Zeitpunkt ihrer Veröffentlichung auf der Website in Kraft, sofern in der neuen Fassung der Richtlinie nichts anderes vorgesehen ist.</p>
				<p><strong>Die Richtlinie wurde von <a href="https://monzi.io/">monzi.io</a> erstellt.</strong></p>`
	},
	not_found_view: {
		title: 'Seite nicht gefunden',
		description: 'Hoppla! Es ist ein Fehler aufgetreten, diese Seite existiert nicht',
		btn_text: 'Startseite'
	},
	modals: {
		character: {
			title: 'ÜBER PERSÖNLICHKEITEN:',
			subtitle: 'Kaufe ein Produkt mit einer MONZI-Figur darin'
		},
		question_form: {
			successTitle: 'Ihre Frage wurde erfolgreich abgeschickt!',
			successDesc:
				'Vielen Dank für Ihre Anfrage! Wir werden Ihnen in Kürze auf Ihre E-Mail Adresse antworten. Einen schönen Tag noch!',
			successButtonText: 'verständlicherweise!',
			errorTitle:
				'Ups... es ist<br />ein Fehler beim<br />Senden Ihrer<br />Nachricht aufgetreten',
			errorDesc:
				'Beim Absenden Ihrer Nachricht ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal',
			errorButtonText: 'Versuchen Sie es erneut'
		}
	},
	forms: {
		question: {
			title: 'Haben Sie Fragen?',
			desc: 'Fragen Sie den Support',
			email_placeholder: 'Ihre E-Mail',
			question_placeholder: 'Ihre Frage',
			button_text: 'Senden Sie',
			checkbox_text:
				'Sie erklären sich mit der Verarbeitung Ihrer <a href="/privacy-policy">persönlichen Daten</a> einverstanden',
			link_text: 'Datenschutzbestimmungen'
		}
	},
	footer: {
		title: 'INFORMATIONEN ZUM UNTERNEHMEN'
	},
	header: {
		links: {
			home: 'Haupt',
			about: 'ÜBER DIE HELDEN',
			how_it_works: 'WIE ES FUNKTIONIERT',
			products: 'Produkte mit MONZI',
			markets: 'WO KAUFEN',
			news: 'Nachrichten',
			faq: 'FAQ'
		}
	},
	validator: {
		required: 'Dieses Feld ist obligatorisch',
		email: 'Dieses Feld muss Ihre E-Mail Adresse enthalten'
	}
}
